import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Divider, Checkbox, Alert, Select } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';
import useDefaultValues from '../../../../hooks/useDefaultValues';

import useDictionaries from '../../../../hooks/useDictionaries';
import { findAll } from '../../../../services/admin/operators/operators';

const dictionaries = {
  operators: findAll,
};

const defaultValues = {
  escortOptions: [],
};

const EscortTab = ({ disabled }) => {
  const form = useFormContext();
  useDefaultValues(form, defaultValues);
  const { t } = useTranslation();

  const [{ operators }] = useDictionaries(dictionaries);

  return (
    <>
      {form.getFieldValue()?.escortOptions?.length === 0 ||
      form.getFieldValue()?.escortOptions?.length === undefined ? (
        <Alert
          message={t('entity.east.permit.necesityEscortTransport')}
          description={t('entity.east.permit.unnecesseryEscortTransport')}
          type="warning"
        />
      ) : (
        <>
          <Alert
            message={t('entity.east.permit.necesityEscortTransport')}
            description={`${t(
              'entity.east.permit.necesseryEscortTransport',
            )} : ${form.getFieldValue()?.escortOptions?.length}`}
            type="warning"
          />

          <Row gutter={{ md: 32, lg: 64 }}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Divider plain>Vehicul de Insotire</Divider>
              <Form.List name="escortOptions">
                {(fields) => (
                  <>
                    {fields.map(({ name, fieldKey }) => (
                      <div key={fieldKey}>
                        <Divider>
                          {t('entity.east.permit.operator')} {fieldKey + 1}
                        </Divider>
                        <Form.Item
                          name={[name, 'operatorId']}
                          fieldKey={[fieldKey, 'operatorId']}
                          label={t('entity.east.permit.operator')}
                        >
                          <Select
                            style={{ width: '100%' }}
                            loading={operators.loading}
                            disabled={disabled}
                            showSearch
                            onChange={() => {
                              form.setFields([
                                {
                                  name: ['escortOptions', name, 'vehicleId'],
                                  value: null,
                                },
                                {
                                  name: [
                                    'escortOptions',
                                    name,
                                    'backupVehicleId',
                                  ],
                                  value: null,
                                },
                              ]);
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .join(' ')
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) !== -1
                            }
                          >
                            {operators.content.map((row) => (
                              <Select.Option
                                value={`${row.cui} ${row.nrAutorizatie} ${row.anAutorizatie}`}
                                key={`${row.cui} ${row.nrAutorizatie} ${row.anAutorizatie}`}
                              >
                                {row.operatorEconomic} ({row.nrAutorizatie}/
                                {row.anAutorizatie},{' '}
                                {t('entity.east.permit.cui')} {row.cui})
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, next) =>
                            prev.escortOptions[fieldKey].operatorId !==
                            next.escortOptions[fieldKey].operatorId
                          }
                        >
                          {({ getFieldValue }) => (
                            <>
                              <Form.Item
                                name={[name, 'vehicleId']}
                                fieldKey={[fieldKey, 'vehicleId']}
                                label={t('entity.east.permit.vehicle')}
                              >
                                <Select
                                  style={{ width: '100%' }}
                                  loading={operators.loading}
                                  disabled={disabled}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .join(' ')
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) !== -1
                                  }
                                >
                                  {operators.content
                                    .filter(
                                      (op) =>
                                        `${op.cui} ${op.nrAutorizatie} ${op.anAutorizatie}` ===
                                        getFieldValue([
                                          'escortOptions',
                                          fieldKey,
                                          'operatorId',
                                        ]),
                                    )
                                    .flatMap((row) =>
                                      row &&
                                      Array.isArray(row.vehiculeAutorizate)
                                        ? row.vehiculeAutorizate
                                        : [],
                                    )
                                    .map((veh) => (
                                      <Select.Option
                                        value={veh.nrInmatriculareAuto}
                                        key={veh.nrInmatriculareAuto}
                                      >
                                        {veh.nrInmatriculareAuto}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </>
                          )}
                        </Form.Item>

                        <Form.Item
                          name={[name, 'backup']}
                          fieldKey={[fieldKey, 'backup']}
                          valuePropName="checked"
                        >
                          <Checkbox disabled={disabled}>
                            {t('entity.east.permit.spareMachine')}
                          </Checkbox>
                        </Form.Item>

                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, next) =>
                            prev.escortOptions[fieldKey].backup !==
                              next.escortOptions[fieldKey].backup ||
                            prev.escortOptions[fieldKey].operatorId !==
                              next.escortOptions[fieldKey].operatorId
                          }
                        >
                          {({ getFieldValue }) => (
                            <>
                              {getFieldValue([
                                'escortOptions',
                                fieldKey,
                                'backup',
                              ]) && (
                                <Form.Item
                                  name={[name, 'backupVehicleId']}
                                  fieldKey={[fieldKey, 'backupVehicleId']}
                                  label={t('entity.east.permit.spareVehicle')}
                                >
                                  <Select
                                    style={{ width: '100%' }}
                                    loading={operators.loading}
                                    disabled={disabled}
                                    showSearch
                                    filterOption={(input, option) =>
                                      option.children
                                        .join(' ')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) !== -1
                                    }
                                  >
                                    {operators.content
                                      .filter(
                                        (op) =>
                                          `${op.cui} ${op.nrAutorizatie} ${op.anAutorizatie}` ===
                                          getFieldValue([
                                            'escortOptions',
                                            fieldKey,
                                            'operatorId',
                                          ]),
                                      )
                                      .flatMap((row) =>
                                        row &&
                                        Array.isArray(row.vehiculeAutorizate)
                                          ? row.vehiculeAutorizate
                                          : [],
                                      )
                                      .map((veh) => (
                                        <Select.Option
                                          value={veh.nrInmatriculareAuto}
                                          key={veh.nrInmatriculareAuto}
                                        >
                                          {veh.nrInmatriculareAuto}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              )}
                            </>
                          )}
                        </Form.Item>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>

              {form.getFieldValue().requiresPolice && (
                <Alert
                  message={t('entity.east.permit.necesseryPoliceEscort')}
                  type="error"
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default EscortTab;
