import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';
import { getAuditDetails } from '../../../../services/east/permits';

const PERMIT_FIELDS = ['allowedLength', 'allowedWidth', 'allowedHeight'];

const ROUTE_FIELDS = [
  'totalDistance',

  'oversizedLength',
  'fareLenght',

  'oversizedWidth',
  'fareWidth',

  'oversizedHeight',
  'fareHeight',

  'allowedWeight',

  'totalWeight',

  'fareWeight',
];

const AXIS_FIELDS = ['axisCount', 'weightPerGroup', 'allowedWeight'];

const ROUTE_OVERALL_FIELDS = ['fareAxisWeight', 'issuedFare', 'fare'];

const PERMIT_OVERALL_FIELDS = [
  'amountFareEUR',
  'amountEUR',
  'exchangeRate',
  'discount',
  'amount',
];
const AuditTab = () => {
  const form = useFormContext();

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: 'Denumire',
        dataIndex: 'field',
        key: 'field',
      },
      {
        title: 'Valoare',
        dataIndex: 'value',
        key: 'value',
      },
      {
        title: 'R',
        dataIndex: 'r',
        key: 'r',
      },
      {
        title: 'NR',
        dataIndex: 'nr',
        key: 'nr',
      },
      {
        title: 'M',
        dataIndex: 'm',
        key: 'm',
      },
      {
        title: 'P',
        dataIndex: 'p',
        key: 'p',
      },
    ],
    [],
  );

  const [audit, setAudit] = useState([]);

  useEffect(() => {
    const { id } = form.getFieldsValue(['id']);

    if (id) {
      getAuditDetails(id).then((res) => {
        setAudit([
          ...PERMIT_FIELDS.map((field) => ({
            field: t(`audit.permit.${field}`),
            value: res[field],
            r: res[`${field}R`],
            nr: res[`${field}NR`],
            m: res[`${field}M`],
            p: res[`${field}P`],
          })),

          ...(res.routeOptions || []).flatMap((opt, idx) => [
            ...ROUTE_FIELDS.map((field) => ({
              field: `${t('entity.east.permit.route')} ${idx + 1} > ${t(
                `audit.route.${field}`,
              )}`,

              value: opt[field],
              r: opt[`${field}R`],
              nr: opt[`${field}NR`],
              m: opt[`${field}M`],
              p: opt[`${field}P`],
            })),

            ...opt.axisWeightOptions
              .filter((axis) => axis.group > 0)
              .flatMap((axis, idx2) =>
                AXIS_FIELDS.map((field) => ({
                  field: `${t('entity.east.permit.route')} ${idx + 1} > ${t(
                    'entity.east.permit.gropedByAxis',
                  )} ${idx2 + 1} > ${t(`audit.axis.${field}`)}`,
                  value: axis[field],
                  r: axis[`${field}R`],
                  nr: axis[`${field}NR`],
                  m: axis[`${field}M`],
                  p: axis[`${field}P`],
                })),
              ),

            ...ROUTE_OVERALL_FIELDS.map((field) => ({
              field: `${t('entity.east.permit.route')} ${idx + 1} > ${t(
                `audit.route.${field}`,
              )}`,
              value: opt[field],
              r: opt[`${field}R`],
              nr: opt[`${field}NR`],
              m: opt[`${field}M`],
              p: opt[`${field}P`],
            })),
          ]),

          ...PERMIT_OVERALL_FIELDS.map((field) => ({
            field: t(`audit.permit.${field}`),
            value: res[field],
            r: res[`${field}R`],
            nr: res[`${field}NR`],
            m: res[`${field}M`],
            p: res[`${field}P`],
          })),
        ]);
      });
    }
  }, [form, t]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={audit}
        pagination={false}
        rowKey="field"
      />
    </>
  );
};

export default AuditTab;
