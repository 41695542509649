import React, { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const LocalDatePicker = ({ value, onChange, format, ...rest }) => {
  const localValue = useMemo(() => {
    if (value) {
      const parsedDate = dayjs(value);
      return parsedDate.isValid() ? parsedDate : null;
    }
    return null;
  }, [value]);

  const handleOnChange = useCallback(
    (date, dateString) => {
      onChange(dateString);
    },
    [onChange],
  );

  return (
    <DatePicker
      value={localValue}
      onChange={handleOnChange}
      format={format}
      {...rest}
    />
  );
};

export default LocalDatePicker;
