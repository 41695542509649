import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Default } from '../../../layouts';

import { useEditPage } from '../../../hooks/useEditPage';

import {
  findOne as findOneHOF,
  create as createHOF,
  update as updateHOF,
} from '../../../services/admin/constants';

import { CONSTANTS } from '../../../components/admin/system/constants';
import EditConstantForm from '../../../components/admin/system/EditConstantForm';

const initial = {};

const EditConstant = () => {
  const { group } = useParams();

  const [constantGroup, setConstantGroup] = useState(null);

  useEffect(() => {
    if (group && Object.keys(CONSTANTS).includes(group.toUpperCase())) {
      setConstantGroup(group.toUpperCase());
    }
  }, [group]);

  const { findOne, create, update } = useMemo(
    () =>
      constantGroup
        ? {
            findOne: (id) =>
              findOneHOF(constantGroup.toLowerCase())(id).then((res) => {
                return res;
              }),
            create: createHOF(constantGroup.toLowerCase()),
            update: updateHOF(constantGroup.toLowerCase()),
          }
        : {
            findOne: () => Promise.resolve({}),
            create: () => Promise.reject(),
            update: () => Promise.reject(),
          },
    [constantGroup],
  );

  const [constant, handleSubmit, handleCancel, errors] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: `/admin/system#${group.toUpperCase()}`,
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {constantGroup && constant && (
        <EditConstantForm
          constant={constant}
          group={constantGroup}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          errors={errors}
        />
      )}
    </Default>
  );
};

export default EditConstant;
