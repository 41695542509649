import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse, Table, Typography, Descriptions } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';

const PaymentTab = () => {
  const form = useFormContext();
  const { t } = useTranslation();

  const permit = useMemo(() => form.getFieldsValue(true), [form]);

  const columns = useMemo(
    () => [
      {
        title: t('entity.east.permit.transroute.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('entity.east.permit.transroute.type'),
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: t('entity.east.permit.transroute.roadName'),
        dataIndex: 'roadName',
        key: 'roadName',
      },
      {
        title: t('entity.east.permit.transroute.roadType'),
        dataIndex: 'roadType',
        key: 'roadType',
      },
      {
        title: t('entity.east.permit.transroute.distance'),
        dataIndex: 'distance',
        key: 'distance',
      },
      {
        title: t('entity.east.permit.transroute.totalDistance'),
        dataIndex: 'totalDistance',
        key: 'totalDistance',
      },
    ],
    [t],
  );

  return (
    <>
      <Typography.Paragraph strong style={{ paddingTop: '1rem' }}>
        {t('entity.east.permit.fareCalulations')}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t('entity.east.permit.inroducedInformation')}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t('entity.east.permit.supplementoryValue')}{' '}
        <strong>{permit.amount} RON</strong>
      </Typography.Paragraph>
      <Collapse accordion>
        {permit.routeOptions.map((route, index) => (
          <Collapse.Panel
            header={`${t('entity.east.permit.route')} ${index + 1}`}
            // eslint-disable-next-line react/no-array-index-key
            key={index + 1}
          >
            {Array.isArray(route.transroute) && route.transroute.length > 0 && (
              <Table
                columns={columns}
                dataSource={route.transroute.sort(
                  (a, b) => a.totalDistance - b.totalDistance,
                )}
                pagination={false}
                rowKey="totalDistance"
                size="small"
              />
            )}

            <Descriptions bordered column={1} style={{ maxWidth: '60rem' }}>
              <Descriptions.Item label={t('entity.east.permit.totalDistance')}>
                {(route.totalDistanceR || 0) +
                  (route.totalDistanceNR || 0) +
                  (route.totalDistanceM || 0) +
                  (route.totalDistanceP || 0)}{' '}
                ({' '}
                <Typography.Text>
                  R = {route.totalDistanceR || 0}
                </Typography.Text>
                {' / '}
                <Typography.Text>
                  NR = {route.totalDistanceNR || 0}
                </Typography.Text>
                {' / '}
                <Typography.Text>
                  M = {route.totalDistanceM || 0}
                </Typography.Text>
                {' / '}
                <Typography.Text>
                  P = {route.totalDistanceP || 0}
                </Typography.Text>{' '}
                )
              </Descriptions.Item>
              <Descriptions.Item label={t('entity.east.permit.dimensionFares')}>
                {route.fareDimension}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('entity.east.permit.totalWeightFares')}
              >
                {route.fareWeight}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('entity.east.permit.totalWeightFares')}
              >
                {route.fareAxisWeight}
              </Descriptions.Item>
              <Descriptions.Item label={t('entity.east.permit.issuingFare')}>
                {route.issuedFare}
              </Descriptions.Item>
              {permit.requestType === 'PERMANENT' ? (
                <Descriptions.Item label={t('entity.east.permit.discount._')}>
                  {permit.discount * 100}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t('entity.east.permit.fareEuro')}>
                {route.fare}
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
        ))}
        <Collapse.Panel
          header={`${t('entity.east.permit._total')}`}
          key="total"
        >
          <Descriptions bordered column={1} style={{ maxWidth: '60rem' }}>
            {permit.routeOptions.map((route, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                <Descriptions.Item
                  label={`${t('entity.east.permit.issuingFare')} ${t(
                    'entity.east.permit.route',
                  )} ${index + 1} (EUR)`}
                >
                  {route.issuedFare}
                </Descriptions.Item>
                <Descriptions.Item
                  label={`${t('entity.east.permit.fare')} ${t(
                    'entity.east.permit.route',
                  )} ${index + 1} (EUR)`}
                >
                  {route.fare}
                </Descriptions.Item>
              </React.Fragment>
            ))}
            <Descriptions.Item label={t('entity.east.permit.amountFareEUR')}>
              {permit.amountFareEUR}
            </Descriptions.Item>
            {permit.requestType === 'PERMANENT' ? (
              <Descriptions.Item label={t('entity.east.permit.discount._')}>
                {permit.discount * 100}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label={t('entity.east.permit.amountEUR')}>
              {permit.amountEUR}
            </Descriptions.Item>
          </Descriptions>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default PaymentTab;
