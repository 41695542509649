import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';

import { Clean } from '../layouts';

const TermsAndConditions = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { Title } = Typography;
  return (
    <Clean>
      {locale === 'ro' && (
        <div
          style={{
            width: '100%',
            maxWidth: '960px',
            margin: '0 auto',
          }}
        >
          <Title level={3}>TERMENI SI CONDITII</Title>

          <p>
            Utilizarea acestui site implica acceptarea neconditionata a
            termenilor si conditiilor de mai jos.
          </p>
          <p>Recomandam citirea cu atentie a acestora</p>
          <p>
            C.N.A.I.R. S.A. isi asuma dreptul de a modifica/actualiza/corecta
            structura, continutul si serviciile in orice moment sau de a
            suspenda functionarea temporara a acestuia, fara o alta notificare.
          </p>
          <Title level={3}>
            1. CONDIŢII GENERALE PENTRU UTILIZAREA SITE-ULUI
          </Title>
          <p>
            Site-ul www.eAST.ro, numit pe parcursul acestor termeni si conditii
            eAST.ro, este proprietatea Companiei Nationale de Administrare a
            Infrastructurii Rutiere S.A. (C.N.A.I.R. S.A.), cu sediul in B-dul
            Dinicu Golescu nr. 38 Sector 1, Bucuresti, inregistrata la Registrul
            Comertului cu numarul J40/552/2004, CUI RO16054368.
          </p>
          <p>
            Prin intermediul site-ului eAST.ro se pot obtine Autorizatii
            Speciale de Transport (AST), pentru vehiculele care circula cu
            depasirea maselor si/sau dimensiunilor maxime admise in circulatie
            pe drumurile publice din Romania.
          </p>
          <p>
            Accesul/vizitarea eAST.ro se supune Termenilor si conditiilor de
            utilizare, implica acceptul explicit cu privire la acestia si
            reprezinta întreaga întelegere dintre parti. Clientul isi da acordul
            impunerii acestor termeni si conditii de utilizare. In situatia in
            care clientul nu este de acord cu acesti termeni, va accesa eAST.ro
            doar in scop informativ si nu in vederea obtinerii unei AST.
            C.N.A.I.R. S.A. isi rezerva dreptul de a modifica acesti termeni si
            conditii de utilizare in orice moment. Orice modificare a acestora
            va fi valabila de indata ce va fi postata pe eAST.ro.
          </p>
          <p>
            Continuarea accesarii si folosirii eAST.ro urmarind modificarea
            termenilor si conditiilor de utilizare constituie acceptarea
            acestora precum si a modificarilor survenite in timp.
          </p>
          <Title level={3}>2. DREPTURI DE PROPRIETATE INTELECTUALĂ </Title>
          <p>
            C.N.A.I.R. S.A. este deţinătoarea eAST.ro. Fotografiile, textele,
            sloganurile, desenele, imaginile, precum şi toate lucrările
            integrate în acest site sunt proprietatea C.N.A.I.R. S.A.
          </p>
          <Title level={3}>
            3. ACCESUL LA INFORMATIILE DISPONIBILE PE eAST.ro
          </Title>
          <p>
            C.N.A.I.R. S.A. ofera tuturor utilizatorilor accesul limitat, in
            interes profesional, pe eAST.ro si nu le confera dreptul de a
            modifica partial sau integral site-ul, de a reproduce partial sau
            integral site-ul, de a copia, de a vinde/ revinde sau de a exploata
            site-ul in orice alta maniera, in scopuri comerciale sau contrare
            intereselor C.N.A.I.R. S.A.
          </p>
          <p>
            Efectuarea de operatiuni neautorizate asupra acestui site si
            tentativa de efectuare a acestora, incluzând fara a se limita la:
            utilizarea abuziva, utilizarea frauduloasa, accesul neautorizat,
            modificarea, copierea de informatii în vederea utilizarii lor,
            blocarea accesului, etc, vor fi considerate tentative de fraudare a
            eAST.ro si va determina plangerea/cercetarea penala impotriva
            aceluia care a initiat aceasta actiune.
          </p>
          <p>
            Continutul eAST.ro nu poate fi copiat, reprodus, transferat,
            incarcat, publicat sau distribuit in orice mod fara a obtine in
            prealabil acordul scris al C.N.A.I.R S.A., exceptie facand
            posibilitatea de a tipari extrase din paginile acestuia doar pentru
            uz personal.
          </p>
          <Title level={3}>4. DEFINITII</Title>
          <p>
            Mase si dimensiuni maxime admise – masele si dimensiunile maxime
            admise ale vehiculelor, pe drumurile publice din Romania, asa cum
            sunt ele prevazute in anexele nr. 2 si 3 ale Ordonantei Guvernului
            nr. 43/1997 privind regimul drumurilor, republicata, cu modificarile
            si completarile ulterioare.
          </p>
          <p>
            Autorizatie Speciala de Transport (AST) - documentul prin care
            administratorul drumului stabileşte traseul ce urmează a fi parcurs,
            condițiile de efectuare a transportului şi tarifele datorate,
            necesar pentru circulația vehiculelor rutiere cu depăşiri ale
            maselor maxime admise şi/sau ale dimensiunilor maxime admise,
            valabil pentru numerele de înmatriculare sau de înregistrare ale
            vehiculelor, pentru caracteristicile tehnice, traseul, sensul,
            perioada şi condiţiile de circulație impuse.
          </p>
          <p>
            Traseu autorizat - traseul impus de către administratorul drumului
            pentru deplasarea vehiculului cu depăşiri, stabilit în funcţie de
            originea şi destinaţia transportului, masele şi dimensiunile
            efective ale vehiculului, limitările impuse de portanţa drumului, de
            elementele infrastructurii drumurilor şi de restricţiile de
            circulaţie permanente şi temporare instituite.
          </p>
          <p>
            Vehicul cu depășiri - orice vehicul, cu sau fără încărcătură, ale
            cărui mase si/sau dimensiuni depăsesc masele si/sau dimensiunile
            maxime admise prevăzute în anexele nr. 2 si 3 la Ordonanta
            Guvernului nr. 43/1997 privind regimul drumurilor, republicată, cu
            modificările si completările ulterioare.
          </p>
          <p>
            Tarife suplimentare de utilizare - tarifele datorate de operatorul
            de transport pentru circulația vehiculelor cu depăsiri pe drumurile
            publice.
          </p>
          <Title level={3}>5. LIMITAREA RESPONSABILITĂŢII </Title>
          <p>
            Clientul foloseste eAST.ro pe riscul propriu, C.N.A.I.R. S.A. fiind
            libera de orice raspundere pentru eventualele daune directe sau
            indirecte, cauzate de utilizarea sau accesarea/vizitarea eAST.ro sau
            ca urmare a utilizarii informatiilor de pe site.
          </p>
          <p>
            C.N.A.I.R. S.A. nu este raspunzatoare de erorile sau omisiunile care
            pot interveni în urma furnizarii unor date incomplete sau incorecte
            de catre client.
          </p>
          <Title level={3}>6. CONFIDENTIALITATEA DATELOR</Title>
          <p>
            Toate documentele si informatiile de orice natura furnizate de catre
            clienti vor fi utilizate numai pentru realizarea scopului eAST.ro si
            anume eliberarea AST.
          </p>
          <p>
            C.N.A.I.R. S.A. este operator de date cu caracter personal si
            prelucrează datele de identificare comunicate de client în vederea
            îndeplinirii obligațiilor legale care îi revin, conform specificului
            activității derulate.
          </p>
          <p>
            Conform atribuțiilor legale, C.N.A.I.R. S.A. prelucrează datele cu
            caracter personal în scopul autorizării circulației vehiculelor cu
            depășiri ale maselor si/ sau dimensiunilor maxime admise prevăzute
            de OG nr. 43/1997 privind regimul drumurilor, republicata, cu
            modificările si completările ulterioare, respectiv emiterea și
            evidența AST.
          </p>
          <p>Datele cu caracter personal prelucrate de C.N.A.I.R. S.A. sunt:</p>
          <p>
            1. Nume, prenume, seria și numărul cartii de identitate/pașaportului
            - pentru identificarea persoanei desemnate să reprezinte clientul și
            anume persoana care completează pe proprie răspundere datele
            necesare pentru eliberarea AST;
          </p>
          <p>
            2. Codul numeric personal - în vederea întocmirii documentelor
            fiscale aferente autorizațiilor speciale de transport (factura);
          </p>
          <p>3. Adresa de email, număr de telefon/fax – pentru comunicare; </p>
          <p>Datele cu caracter personal sunt stocate pentru o perioadă de: </p>
          <p>
            a) 5 (cinci) ani pentru datele înscrise în cererile pentru
            eliberarea autorizațiilor speciale de transport și în registrul de
            evidență a autorizațiilor speciale de transport predate;
          </p>
          <p>
            b) 10 (zece) ani pentru datele înscrise în documentele fiscale
            (factură)
          </p>
          <p>
            Datele cu caracter personal prelucrate de C.N.A.I.R. S.A. pot fi
            puse la cerere, la dispoziția:
          </p>
          <p>
            a) autorităților guvernamentale și judecătorești și alte persoane
            juridice implicate în proceduri legale;
          </p>
          <p>
            b) organe de cercetare penală, autorități fiscale, precum și către
            alte persoane juridice față de care dezvăluirea este cerută de lege;
          </p>
          <p>
            c) persoane juridice cu competențe, potrivit legii, în derularea
            operațiunilor referitoare la recuperarea creanțelor;
          </p>
          <p>
            Prin acceptarea prezentei Note de informare vă exprimați în mod
            expres și neechivoc consimțământul pentru prelucrarea datelor
            dumneavoastră cu caracter personal, de către C.N.A.I.R. S.A.
          </p>
          <Title level={3}>
            7. INREGISTRARE/ CONT DE UTILIZATOR, PAROLE SI RESPONSABILITATI
          </Title>
          <p>
            Accesul clientului la facilitatile din cadrul eAST.ro necesita
            crearea unui cont de utilizator prin intermediul unei adrese de
            e-mail valide, a unei parole si a Codului Unic de Inregistrare la
            Oficiul Registrul Comertului, in cazul persoanelor juridice sau
            codului numeric personal in cazul persoanelor fizice.
          </p>
          <p>
            Inregistrarea este gratuita. Se pot inregistra numai persoanele
            juridice si persoanele fizice care detin capacitatea deplina de a
            incheia acte juridice. In special, se interzice inregistrarea
            minorilor.
          </p>
          <p>
            Informatiile pe care trebuie sa la furnizeze un client in timpul
            inregistrarii trebuie sa fie complete si corecte. Inregistrarea
            necesita o adresa de e-mail valida. Clientul trebuie sa aleaga o
            parola personala, confidentiala si care nu trebuie dezvaluita
            tertilor.
          </p>
          <p>
            C.N.A.I.R. S.A. nu poate fi responsabila pentru erorile survenite ca
            urmare a neglijentei clientului, in ceea ce priveste pastrarea
            confidentialitatii datelor de acces.
          </p>
          <p>
            Fiecare client se poate inregistra o singura data pe eAST.ro. Contul
            de utilizator nu este transferabil.
          </p>
          <p>
            C.N.A.I.R. S.A. nu va solicita niciodata parola conturilor
            dumneavoastra in mesaje transmise prin posta electronica sau
            telefon.
          </p>
          <p>
            Pentru activarea contului, clientul se va adresa subunitatilor
            C.N.A.I.R. S.A., conform instructiunilor primite prin e-mail si a
            informatiilor publicate pe site-ul C.N.A.I.R. S.A., la adresa de
            internet WWW.cnadnr.ro.
          </p>
          <p>
            C.N.A.I.R. S.A. isi rezerva dreptul de a nu activa conturile de
            utilizator in cazul in care clientul a furnizat date incomplete sau
            incorecte.
          </p>
          <p>
            C.N.A.I.R. S.A. isi rezerva dreptul de a inactiva conturile de
            utilizator neutilizate pentru o perioada mai lunga de timp sau in
            cazul in care clientul are, in mod repetat plati restante.
          </p>
          <Title level={3}>8. PLATA SI CONDITII DE PLATA </Title>
          <p>
            Clientul poate achita tarifele suplimentare de utilizare prin
            intermediul eAST.ro numai daca detine un cont de utilizator activ si
            un card bancar valid.
          </p>
          <p>
            Prin acceptarea platii pe eAST.ro clientul este de acord cu forma de
            comunicare prin care C.N.A.I.R. S.A. deruleaza operatiunile de
            incasare a tarifelor de utilizare.
          </p>
          <p>
            Dupa validarea comenzii, prin bifarea campului: “Plateste” clientul
            nu mai poate reveni asupra acesteia, comanda lansata fiind certa si
            exigibila, iar plata fiind obligatorie pentru acesta.
          </p>
          <p>
            Factura aferenta AST se va emite pe baza datelor furnizate de catre
            client, asa cum au fost introduse la crearea contului sau cum au
            fost actualizate/modificate in sectiunea specifica din eAST.ro.
          </p>
          <p>
            C.N.A.I.R. S.A. nu este responsabila si nu poate fi raspunzatoare
            pentru furnizarea de catre client a unor date incorecte, incomplete
            si/sau neadevarate.
          </p>
          <p>
            Recomandam clientilor sa verifice datele furnizate catre C.N.A.I.R.
            S.A., in baza carora se va emite factura aferenta comenzilor.
          </p>
          <p>
            Emiterea AST se va efectua numai dupa debitatrea din contul bancar
            al clientului a sumei aferente, iar data de inceput a valabilitatii
            AST nu poate fi anterioara acestei date.
          </p>
          <p>
            AST emise nu se pot anula, iar contravaloarea tarifelor aferente, nu
            se returneaza.
          </p>
          <Title level={3}>9. OBLIGATIILE CNAIR </Title>
          <p>
            C.N.A.I.R. S.A. va emite AST conform cu datele completate de catre
            client si numai in conditiile in care acestea se incadreaza in
            conditiile de emitere a AST si doar dupa confirmarea platii.
            Clientul va regasi factura si in contul creat pe eAST.ro. Clientul
            va primi pe adresa de e-mail cu care a deschis contul pe eAST.ro,
            din partea procesatorului de plati agreat de C.N.A.I.R. S.A. detalii
            privind plata efectuata.
          </p>
        </div>
      )}
      {locale === 'en' && (
        <div
          style={{
            width: '100%',
            maxWidth: '960px',
            margin: '0 auto',
          }}
        >
          <Title level={3}>TERMS AND CONDITIONS</Title>

          <p>
            The use of this site implies the unconditional acceptance of the
            terms and conditions outlined below.
          </p>
          <p>We recommend that you carefully read these terms.</p>
          <p>
            C.N.A.I.R. S.A. reserves the right to modify/update/correct the
            structure, content, and services at any time or to temporarily
            suspend the operation of the site without prior notice.
          </p>
          <Title level={3}>1. GENERAL TERMS FOR USING THE SITE</Title>
          <p>
            The site www.eAST.ro, referred to throughout these terms and
            conditions as eAST.ro, is owned by the National Company for Road
            Infrastructure Administration S.A. (C.N.A.I.R. S.A.), headquartered
            at B-dul Dinicu Golescu nr. 38 Sector 1, Bucharest, registered with
            the Trade Register under number J40/552/2004, CUI RO16054368
          </p>
          <p>
            Special Transport Authorizations (AST) can be obtained through
            eAST.ro for vehicles that exceed the maximum allowable weights
            and/or dimensions on public roads in Romania.
          </p>
          <p>
            Accessing/visiting eAST.ro is subject to the Terms and Conditions of
            Use, implies explicit acceptance thereof, and represents the entire
            agreement between the parties. The client agrees to be bound by
            these terms and conditions of use. If the client does not agree with
            these terms, they will access eAST.ro solely for informational
            purposes and not for obtaining an AST. C.N.A.I.R. S.A. reserves the
            right to modify these terms and conditions of use at any time. Any
            modification will be effective immediately after posting on eAST.ro.
          </p>
          <p>
            Continuing to access and use eAST.ro following any changes to the
            terms and conditions of use constitutes acceptance of these terms as
            well as any subsequent modifications.
          </p>
          <Title level={3}>2. INTELLECTUAL PROPERTY RIGHTS </Title>
          <p>
            C.N.A.I.R. S.A. is the owner of eAST.ro. The photographs, texts,
            slogans, designs, images, as well as all works integrated into this
            site are the property of C.N.A.I.R. S.A.
          </p>
          <Title level={3}>3. ACCESS TO INFORMATION AVAILABLE ON eAST.ro</Title>
          <p>
            C.N.A.I.R. S.A. offers all users limited access to eAST.ro for
            professional purposes and does not grant them the right to partially
            or entirely modify the site, to partially or entirely reproduce the
            site, to copy, sell/resell, or exploit the site in any other manner
            for commercial purposes or purposes contrary to the interests of
            C.N.A.I.R. S.A.
          </p>
          <p>
            Unauthorized operations on this site and attempts to carry out such
            operations, including but not limited to: abusive use, fraudulent
            use, unauthorized access, modification, copying of information for
            the purpose of its use, blocking access, etc., will be considered
            attempts to defraud eAST.ro and will lead to criminal
            complaints/investigations against those who initiated such actions.
          </p>
          <p>
            The content of eAST.ro may not be copied, reproduced, transferred,
            uploaded, published, or distributed in any way without prior written
            consent from C.N.A.I.R S.A., except for the possibility of printing
            extracts from its pages for personal use only
          </p>
          <Title level={3}>4. DEFINITIONS</Title>
          <p>
            Maximum Allowed Weights and Dimensions - the maximum allowed weights
            and dimensions of vehicles on public roads in Romania, as provided
            in Annexes 2 and 3 of Government Ordinance No. 43/1997 regarding
            road regulations, republished, with subsequent amendments and
            completions.
          </p>
          <p>
            Special Transport Authorization (AST) - a document by which the road
            administrator establishes the route to be followed, the conditions
            for conducting the transport, and the fees owed, necessary for the
            circulation of vehicles exceeding the maximum permissible weights
            and/or dimensions, valid for the vehicle`s registration numbers,
            technical characteristics, route, direction, period, and imposed
            traffic conditions.
          </p>
          <p>
            Authorized Route - the route imposed by the road administrator for
            the movement of an oversize vehicle, established based on the origin
            and destination of the transport, the actual weights and dimensions
            of the vehicle, the limitations imposed by road load-bearing
            capacity, road infrastructure elements, and the permanent and
            temporary traffic restrictions imposed.
          </p>
          <p>
            Oversize Vehicle - any vehicle, with or without load, whose weights
            and/or dimensions exceed the maximum permissible weights and/or
            dimensions provided in Annexes 2 and 3 of Government Ordinance No.
            43/1997 regarding road regulations, republished, with subsequent
            amendments and completions.
          </p>
          <p>
            Additional Usage Fares - fees owed by the transport operator for the
            circulation of oversize vehicles on public roads.
          </p>
          <Title level={3}>5. LIMITATION OF LIABILITY </Title>
          <p>
            The client uses eAST.ro at their own risk, and C.N.A.I.R. S.A. is
            free of any liability for any direct or indirect damages caused by
            the use or access/visit to eAST.ro or as a result of the use of the
            information on the site.
          </p>
          <p>
            C.N.A.I.R. S.A. is not responsible for errors or omissions that may
            occur due to incomplete or incorrect data provided by the client.
          </p>
          <Title level={3}>6. DATA CONFIDENTIALITY</Title>
          <p>
            All documents and information of any nature provided by clients will
            be used solely for the purpose of eAST.ro, namely the issuance of
            AST.
          </p>
          <p>
            C.N.A.I.R. S.A. is a data controller and processes the
            identification data provided by the client to fulfill its legal
            obligations, according to the specific nature of its activities.
          </p>
          <p>
            According to its legal duties, C.N.A.I.R. S.A. processes personal
            data for the purpose of authorizing the circulation of vehicles
            exceeding the maximum permissible weights and/or dimensions provided
            by Government Ordinance No. 43/1997 regarding road regulations,
            republished, with subsequent amendments and completions, and for
            issuing and keeping records of AST.
          </p>
          <p>The personal data processed by C.N.A.I.R. S.A. includes:</p>
          <p>
            1. Name, surname, identity card/passport series and number - for
            identifying the person designated to represent the client, who fills
            out the necessary data for the issuance of AST on their own
            responsibility;
          </p>
          <p>
            2. Personal identification number - for issuing fiscal documents
            related to special transport authorizations (invoice);
          </p>
          <p>
            3. Email address, phone/fax number - for communication purposes;{' '}
          </p>
          <p>Personal data is stored for a period of: </p>
          <p>
            a) 5 (five) years for data entered in applications for special
            transport authorizations and in the register of issued special
            transport authorizations;
          </p>
          <p>
            b) 10 (ten) years for data entered in fiscal documents (invoice);
          </p>
          <p>
            Personal data processed by C.N.A.I.R. S.A. may be made available,
            upon request, to:
          </p>
          <p>
            a) Governmental and judicial authorities and other legal entities
            involved in legal procedures;
          </p>
          <p>
            b) Criminal investigation bodies, tax authorities, and other legal
            entities to whom disclosure is required by law;
          </p>
          <p>
            c) Legal entities with legal competence in conducting operations
            related to debt recovery;
          </p>
          <p>
            By accepting this Information Note, you expressly and unequivocally
            consent to the processing of your personal data by C.N.A.I.R. S.A.
          </p>
          <Title level={3}>
            7. REGISTRATION/USER ACCOUNT, PASSWORDS, AND RESPONSIBILITIES
          </Title>
          <p>
            Access to the facilities provided by eAST.ro requires the creation
            of a user account through a valid email address, a password, and the
            Unique Registration Code at the Trade Registry Office for legal
            entities, or the personal identification number for natural persons.
          </p>
          <p>
            Registration is free. Only legal entities and natural persons with
            full legal capacity to conclude legal acts can register.
            Registration of minors is explicitly prohibited.
          </p>
          <p>
            The information that a client must provide during registration must
            be complete and correct. Registration requires a valid email
            address. The client must choose a personal, confidential password
            that should not be disclosed to third parties.
          </p>
          <p>
            C.N.A.I.R. S.A. cannot be held responsible for errors resulting from
            the client`s negligence regarding the confidentiality of access
            data.
          </p>
          <p>
            Each client can register only once on eAST.ro. The user account is
            non-transferable.
          </p>
          <p>
            C.N.A.I.R. S.A. will never ask for your account passwords via email
            or phone.
          </p>
          <p>
            To activate the account, the client must contact the subunits of
            C.N.A.I.R. S.A., according to the instructions received by email and
            the information published on the C.N.A.I.R. S.A. website, at the
            internet address www.cnadnr.ro.
          </p>
          <p>
            C.N.A.I.R. S.A. reserves the right not to activate user accounts if
            the client has provided incomplete or incorrect data.
          </p>
          <p>
            C.N.A.I.R. S.A. reserves the right to deactivate user accounts that
            have not been used for an extended period or if the client
            repeatedly has outstanding payments.
          </p>
          <Title level={3}>8. PAYMENT AND PAYMENT TERMS </Title>
          <p>
            The client may pay additional usage fees through eAST.ro only if
            they have an active user account and a valid bank card.
          </p>
          <p>
            By accepting payment on eAST.ro, the client agrees to the form of
            communication through which C.N.A.I.R. S.A. conducts fee collection
            operations.
          </p>
          <p>
            After the order is validated by ticking the “Pay” box, the client
            can no longer cancel the order, the placed order is final and
            enforceable, and the payment becomes mandatory.
          </p>
          <p>
            The invoice for the AST will be issued based on the data provided by
            the client as entered when creating the account or as
            updated/modified in the specific section of eAST.ro.
          </p>
          <p>
            C.N.A.I.R. S.A. is not responsible and cannot be held liable for the
            provision of incorrect, incomplete, and/or false data by the client.
          </p>
          <p>
            We recommend that clients verify the data provided to C.N.A.I.R.
            S.A., based on which the invoice for orders will be issued.
          </p>
          <p>
            The AST will be issued only after the corresponding amount is
            debited from the client`s bank account, and the start date of the
            AST validity cannot be earlier than this date.
          </p>
          <p>
            Issued ASTs cannot be canceled, and the corresponding fees are
            non-refundable.
          </p>
          <Title level={3}>9. C.N.A.I.R.`s OBLIGATIONS</Title>
          <p>
            C.N.A.I.R. S.A. will issue the Special Transport Authorization (AST)
            based on the data provided by the client, only if such data complies
            with the conditions for issuing the AST and after payment
            confirmation. The client will find the invoice in the account
            created on eAST.ro. The client will also receive, via the email
            address used to open the account on eAST.ro, details of the payment
            from the payment processor approved by C.N.A.I.R. S.A.
          </p>
        </div>
      )}
    </Clean>
  );
};

export default TermsAndConditions;
