import React from 'react';
import { Link } from 'react-router-dom';

import { Layout } from 'antd';

import logoImage from '../assets/logo.png';
import LanguageChooserDark from '../components/LanguageChooserDark';

const Clean = ({ children }) => {
  return (
    <>
      <Layout>
        <Layout.Header>
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <div style={{ color: 'white', width: '95%' }}>
              <Link to="/">
                <img
                  src={logoImage}
                  alt="eAST"
                  width="36"
                  height="36"
                  style={{
                    marginRight: '.5rem',
                    boxSizing: 'content-box',
                    border: '2px solid #fff',
                  }}
                />{' '}
                eAST
              </Link>
            </div>
            <LanguageChooserDark />
          </div>
        </Layout.Header>

        <Layout>
          <Layout
            style={{
              height: '100vh',
              minHeight: '400px',
              borderRight: 0,
              paddingTop: '64px',
            }}
          >
            <div
              style={{
                padding: 24,
                margin: 0,
                height: '100%',
              }}
            >
              <Layout.Content
                style={{ background: 'white', minHeight: '100%', padding: 24 }}
              >
                {children}
              </Layout.Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Clean;
