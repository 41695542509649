import React from 'react';

import { Button, Dropdown, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const LanguageChooserDark = () => {
  const { i18n } = useTranslation();

  const setLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('locale', lang);
  };

  const menu = (
    <Menu>
      <Menu.Item key="ro">
        <Button type="text" onClick={() => setLang('ro')}>
          Romana
        </Button>
      </Menu.Item>
      <Menu.Item key="en">
        <Button type="text" onClick={() => setLang('en')}>
          English
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} theme="dark">
      <Button type="text">
        <GlobalOutlined
          style={{
            color: 'white',
            'margin-top': '20px',
          }}
        />
      </Button>
    </Dropdown>
  );
};

export default LanguageChooserDark;
