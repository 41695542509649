import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { SERVICE_URI, BASE_URI } from '../../services/east/permits';

const DownloadASTButton = ({ id, language }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      icon={<FileDoneOutlined />}
      href={`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/view/${id}/ast/${language}`}
    >
      {t('actions.ast')}
    </Button>
  );
};

export default DownloadASTButton;
