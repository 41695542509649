import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Layout, Menu } from 'antd';

import AuthContext from '../components/auth';
import { SERVICE_URI, BASE_URI } from '../services/auth';
import Sidebar from '../components/Sidebar';

import logoImage from '../assets/logo.png';
import LanguageChooserDark from '../components/LanguageChooserDark';

const Default = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const handleLogout = () => {
    window.location.href = `${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/logout`;
  };

  return (
    <>
      <Layout>
        <Layout.Header>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignContent: 'space-between',
            }}
          >
            <div style={{ color: 'white', width: '200px' }}>
              <a href="/">
                <img
                  src={logoImage}
                  alt="eAST"
                  width="36"
                  height="36"
                  style={{
                    marginRight: '.5rem',
                    boxSizing: 'content-box',
                    border: '2px solid #fff',
                  }}
                />{' '}
                eAST
              </a>
            </div>
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ flexGrow: 1, justifyContent: 'flex-end' }}
            >
              <Menu.SubMenu
                key="account"
                title={user.email}
                popupOffset={[0, -10]}
              >
                <Menu.Item
                  key="personal-info"
                  onClick={() => history.push('/personal-info')}
                >
                  {t('nav.personalInfo')}
                </Menu.Item>
                <Menu.Item key="logout" onClick={handleLogout}>
                  {t('nav.logout')}
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
            <LanguageChooserDark />
          </div>
        </Layout.Header>

        <Layout>
          <Layout.Sider
            width={240}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
          >
            <Sidebar />
          </Layout.Sider>
          <Layout
            style={{
              height: '100vh',
              minHeight: '400px',
              borderRight: 0,
              paddingTop: '64px',
              paddingLeft: '240px',
            }}
          >
            <div
              style={{
                padding: 24,
                margin: 0,
                height: '100%',
              }}
            >
              <Layout.Content
                style={{ background: 'white', minHeight: '100%', padding: 24 }}
              >
                {children}
              </Layout.Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default Default;
