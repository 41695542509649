import React, { useMemo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Table,
  Row,
  Col,
  Typography,
  Divider,
  Space,
  Button,
  Input,
  DatePicker,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import useDatasource, { filterDictionary } from '../../hooks/useDatasource';
import useDictionaries from '../../hooks/useDictionaries';

import { findAll as findAllOrigins } from '../../services/admin/origins/origins';
import {
  findAll,
  exportCSV,
  SERVICE_URI,
  BASE_URI,
} from '../../services/east/permits';

import EditItemIcon from '../buttons/EditItemIcon';
import AddEntityButton from '../buttons/AddEntityButton';
import ViewItemIcon from '../buttons/ViewItemIcon';
import DownloadCalcReport from '../buttons/DownloadCalcReport';
import DownloadInvoice from '../buttons/DownloadInvoice';
import DownloadAST from '../buttons/DownloadAST';

import Column from '../../helpers/Columns';
import ExportEntitiesButton from '../buttons/ExportEntitiesButton';

import AuthContext, { hasPermission } from '../auth/index';
import ViewPermitIcon from '../buttons/ViewPermitIcon';

const statusMapping = {
  NEW: ['yellow', 'IN_PROGRESS'],
  VEHICLE: ['yellow', 'IN_PROGRESS'],
  ROUTE: ['yellow', 'IN_PROGRESS'],
  ESCORT: ['yellow', 'IN_PROGRESS'],
  TERMS: ['yellow', 'IN_PROGRESS'],
  PAYMENT: ['red', 'UNPAID'],
  PAID: ['green', 'PAID'],
  CANCELLED: ['#999', 'CANCELLED'],
  DENIED: ['#999', 'CANCELLED'],

  OUTDATING: ['yellow', 'IN_PROGRESS'],
  OUTDATED: ['red', 'UNPAID'],
  OUTDATED_PAID: ['#999', 'CANCELLED'],
};
const statuses = {
  content: [
    {
      name: 'IN_PROGRESS',
      id: ['NEW', 'VEHICLE', 'ROUTE', 'ESCORT', 'TERMS', 'OUTDATING'],
    },
    { name: 'UNPAID', id: ['PAYMENT', 'OUTDATED'] },
    { name: 'CANCELLED', id: ['CANCELLED', 'DENIED'] },
    { name: 'PAID', id: ['PAID'] },
  ],
};
const FilterIcon = (filtered) => (
  <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
);
const FilterDialog = ({
  title,
  searchTitle,
  resetTitle,
  confirm,
  clearFilters,
  children,
  setKey,
}) => (
  <div style={{ padding: 16, paddingTop: 16 }}>
    <Typography.Paragraph>{title}</Typography.Paragraph>
    {children}
    <Divider />
    <Space>
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 120 }}
      >
        {searchTitle}
      </Button>
      <Button
        onClick={(val) => {
          clearFilters(val);
          setKey('');
        }}
        size="small"
        style={{ width: 120 }}
      >
        {resetTitle}
      </Button>
    </Space>
  </div>
);
const filterOrigins = ({
  title = 'Filter',
  searchTitle = 'Search',
  resetTitle = 'Reset',
  key,
  setKey,
}) => ({
  filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
    <FilterDialog
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      clearFilters={clearFilters}
      confirm={confirm}
      setKey={setKey}
    >
      <Input
        value={key}
        onChange={(e) => setKey(e.target.value)}
        onBlur={() => {
          if (key && key.length > 0) {
            findAllOrigins({
              pageSize: 5000,
              criterias: { name: key },
            }).then((res) => {
              setSelectedKeys(
                res?.content?.length > 0
                  ? res?.content?.map(({ id }) => id)
                  : [NaN],
              );
            });
          } else {
            setSelectedKeys([]);
          }
        }}
        onPressEnter={() => {
          if (key && key.length > 0) {
            findAllOrigins({
              pageSize: 5000,
              criterias: { name: key },
            }).then((res) => {
              setSelectedKeys(
                res?.content?.length > 0
                  ? res?.content?.map(({ id }) => id)
                  : [NaN],
              );
              confirm();
            });
          } else {
            setSelectedKeys([]);
            confirm();
          }
          return false;
        }}
        style={{ marginBottom: 8, display: 'block' }}
      />
    </FilterDialog>
  ),
  filterIcon: FilterIcon,
});

export const filterDateModified = ({
  title = 'Filter',
  searchTitle = 'Search',
  resetTitle = 'Reset',
}) => ({
  filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
    <FilterDateWrapper
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      setSelectedKeys={setSelectedKeys}
      clearFilters={clearFilters}
      confirm={confirm}
    />
  ),
  filterIcon: FilterIcon,
});

const DATE_START = '1900-01-01';
const DATE_END = '3000-01-01';
const EMPTY_DATE_RANGE = [null, null];

const FilterDateWrapper = ({
  title,
  searchTitle,
  resetTitle,
  setSelectedKeys,
  confirm,
  clearFilters,
}) => {
  const [value, setValue] = useState(EMPTY_DATE_RANGE);

  const onChange = useCallback(
    ([start, end]) => {
      if (!start && !end) {
        setValue(EMPTY_DATE_RANGE);
        setSelectedKeys(null);
      } else {
        setValue([start, end]);
        setSelectedKeys([
          {
            from: start
              ? start
                  .set('hour', 0)
                  .set('minute', 0)
                  .set('second', 0)
                  .set('millisecond', 0)
                  .toJSON()
              : DATE_START,
            to: end
              ? end
                  .set('hour', 23)
                  .set('minute', 59)
                  .set('second', 59)
                  .set('millisecond', 999)
                  .toJSON()
              : DATE_END,
          },
        ]);
      }
    },
    [setSelectedKeys],
  );

  const onClearFilters = useCallback(() => {
    clearFilters();
    setValue(EMPTY_DATE_RANGE);
  }, [clearFilters]);

  return (
    <FilterDialog
      title={title}
      searchTitle={searchTitle}
      resetTitle={resetTitle}
      confirm={confirm}
      clearFilters={onClearFilters}
    >
      <DatePicker.RangePicker
        allowClear={false}
        allowEmpty={[true, true]}
        style={{ width: '18rem' }}
        value={value}
        onChange={onChange}
      />
    </FilterDialog>
  );
};

const dictionaries = {
  origins: findAllOrigins,
};

const PermitsList = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const [key, setKey] = useState('');

  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const [{ origins }] = useDictionaries(dictionaries);

  const { loading, pagination, content, handleChange, criterias } =
    useDatasource(findAll);

  const getOriginById = useCallback(
    (id) => {
      // eslint-disable-next-line eqeqeq
      const res = (origins.content || []).find((origin) => origin.id == id);
      return res ? res.name : id;
    },
    [origins],
  );

  const columns = useMemo(
    () => [
      // Column.text('id', t('entity.east.permit.id'), {
      //   width: 120,
      //   filter: true,
      // }),
      Column.text('serialNumber', t('entity.east.permit.serialNumber'), {
        width: 200,
        filter: true,
      }),
      Column.text('transportLegalName', t('entity.east.permit.transport'), {
        width: 250,
        filter: true,
      }),
      Column.text('vehiclePlateNumber', t('entity.east.permit.plate'), {
        width: 120,
        filter: true,
      }),

      {
        title: t('entity.east.permit.approvedAt'),
        key: 'approvedAt',
        width: 120,
        dataIndex: 'approvedAt',
        sorter: true,
        ...filterDateModified({ title: t('entity.east.permit.approvedAt') }),
        render: (value) => (value ? moment(value).format('YYYY-MM-DD') : ''),
      },

      {
        title: t('entity.east.permit.routes'),
        key: 'routes',
        width: 150,
        dataIndex: 'routes',
        ...filterOrigins({
          title: t('entity.east.permit.routes'),
          key,
          setKey,
        }),
        render: (routes) => {
          if (!Array.isArray(routes)) {
            return <></>;
          }

          return new Array(routes.length / 2).fill(null).map((_, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx}>
              {routes[idx * 2] && routes[idx * 2 + 1] ? (
                <>
                  <span>{getOriginById(routes[idx * 2])}</span> -&gt;{' '}
                  <span>{getOriginById(routes[idx * 2 + 1])}</span>
                </>
              ) : null}
            </div>
          ));
        },
      },

      {
        title: t('entity.east.permit.valability'),
        key: 'valability',
        width: 120,
        dataIndex: 'valability',
        sorter: false,
        ...filterDateModified({
          title: t('entity.east.permit.valability'),
        }),
        render: (_, obj) =>
          obj.periodStartDate
            ? `${
                obj.periodStartDate
                  ? moment(obj.periodStartDate).format('YYYY-MM-DD')
                  : ''
              } - ${
                obj.periodEndDate
                  ? moment(obj.periodEndDate).format('YYYY-MM-DD')
                  : ''
              }`
            : null,
      },

      {
        title: t('entity.east.permit.amount'),
        key: 'amount',
        width: 120,
        dataIndex: 'amount',
        render: (value) => (
          <div style={{ textAlign: 'right' }}>
            {value && parseFloat(value) > 0
              ? parseFloat(value).toFixed(2)
              : null}
          </div>
        ),
      },

      Column.date('createdAt', t('entity.east.permit.createdAt'), {
        width: 120,
        format: 'YYYY-MM-DD',
        filter: true,
      }),
      {
        title: t('entity.east.permit.status._'),
        key: 'status',
        width: 120,
        dataIndex: 'status',
        ...filterDictionary({
          title: t('entity.east.permit.status._'),
          dictionary: statuses,
          dictKey: 'id',
          dictLabel: (val) => t(`entity.east.permit.shortStatus.${val.name}`),
        }),
        render: (value) => {
          const [color, shortStatus] = statusMapping[value];
          return (
            <Badge
              color={color}
              text={t(`entity.east.permit.shortStatus.${shortStatus}`)}
            />
          );
        },
      },

      Column.actions(t('table.actions'), (record) => (
        <span style={{ textAlign: 'right' }}>
          <Row gutter={16} align="middle">
            <Col span={8}>
              {['PAID', 'CANCELLED', 'DENIED', 'OUTDATED_PAID'].includes(
                record.status,
              ) ? (
                <ViewPermitIcon path={`/east/${record.id}`} />
              ) : (
                <EditItemIcon path={`/east/${record.id}`} />
              )}
            </Col>
            {record.status === 'PAID' && (
              <>
                <Col span={8}>
                  <ViewItemIcon path={`/east/view/${record.ident}`} />
                  {hasPermission(permissions, [
                    'SYS_SUPERUSER',
                    'CNAIR_CONTROL',
                  ]) && (
                    <DownloadCalcReport
                      path={`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/${record.id}/calc`}
                    />
                  )}
                </Col>
                <Col span={8}>
                  <DownloadInvoice
                    path={`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/${record.id}/invoice/${locale}`}
                  />

                  <DownloadAST
                    path={`${window._env_.API_BACKEND_URL}${SERVICE_URI}${BASE_URI}/${record.id}/ast/${locale}`}
                  />
                </Col>
              </>
            )}
          </Row>
        </span>
      )),
    ],
    [t, getOriginById, permissions, key, locale],
  );

  return (
    <>
      <PageHeader
        title={t('entity.east.permit._plural')}
        extra={[
          <>
            <ExportEntitiesButton
              params={criterias}
              downloadFiles={exportCSV}
            />
            <AddEntityButton
              key="new"
              entity={t('entity.east.permit._singular')}
              path="/east/new"
            />
          </>,
        ]}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
      />
    </>
  );
};

export default PermitsList;
