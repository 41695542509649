import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const DivisibleSelect = ({ value, onSelect, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Select
      {...rest}
      style={{ width: '100%' }}
      value={value}
      onSelect={onSelect}
    >
      <Select.Option value={false} key="false">
        {t('entity.east.permit.indivizible')}
      </Select.Option>
      <Select.Option value key="true">
        {t('entity.east.permit.divizible')}
      </Select.Option>
    </Select>
  );
};

export default DivisibleSelect;
