import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Radio } from 'antd';

import useFormContext from '../../../../hooks/useFormContext';
import useDefaultValues from '../../../../hooks/useDefaultValues';

const defaultValues = {
  accept: null,
};

const TermsTab = ({ disabled }) => {
  const form = useFormContext();
  useDefaultValues(form, defaultValues);
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const options = useMemo(
    () => [
      { label: t('entity.east.permit.agree'), value: true },
      { label: t('entity.east.permit.disagree'), value: false },
    ],
    [t],
  );

  return (
    <>
      {locale === 'ro' && (
        <div style={{ height: '400px', overflow: 'auto', margin: '4rem 0' }}>
          <p>
            SUBSEMNATUL {form.getFieldValue('applicantName')} AM LUAT CUNOSTINTA
            CU PRIVIRE LA:
          </p>
          <p>
            Dupa afisarea sumei de plata, clientul poate accepta plata sau poate
            anula solicitarea.
          </p>

          <p>
            Odata cu acceptarea platii, prin bifarea campului “Plateste”
            clientul se angajeaza definitiv si irevocabil sa vireze suma
            reprezentand tariful suplimentar de utilizare in contul C.N.A.I.R.
            S.A. Acceptarea nu mai poate fi anulata.
          </p>

          <p>
            Clientul trebuie sa efectueze plata pana cel tarziu ora 24.00 a
            zilei anterioare primei zile de valabilitate a AST.
          </p>

          <p>
            In cazul neefectuarii platii, solicitarea se transforma in estimare
            si clientul va fi debitat cu echivalentul in lei a sumei de 50 Euro
            (TVA inclus).
          </p>

          <p>
            In cazul in care are plati neachitate, clientul nu va mai putea
            initia alta solicitare, pana la achitarea sumelor datorate catre
            C.N.A.I.R. S.A.
          </p>

          <p>
            C.N.A.I.R. S.A. isi rezerva dreptul de a actiona prin toate caile
            legale pentru recuperarea sumelor neachitate.
          </p>

          <p>
            Circulația în zilele de sâmbătă, duminică, în zilele premergătoare
            zilelor de sărbătoare legală şi în zilele de sărbătoare legală,
            între orele 6.00 și 22.00 este permisă numai dacă în AST este
            înscrisă această posibilitate;
          </p>

          <p>
            Vehiculele cu depasiri pot circula pe drumurile publice numai daca:
          </p>

          <p>a) sunt admise in circulatie;</p>

          <p>
            b) corespund din punct de vedere al starii tehnice si al cerintelor
            de siguranta a circulatiei;
          </p>
          <p>
            c) nu depasesc masele maxime admise constructiv ale vehiculelor;
          </p>
          <p>
            d) detin AST eliberata de administratorul drumului pe care circula
            si respecta prevederile inscrise in AST;
          </p>
          <p>
            Titularul AST este operatorul de transport, care poartă întreaga
            responsabilitate a circulației vehiculului cu depășiri și are
            obligația de a respecta condițiile impuse prin AST ;
          </p>
          <p>
            O copie a AST trebuie să se afle la bordul vehiculului cu depășiri
            pe întreaga perioadă de circulație, fiind obligatorie prezentarea
            acesteia la controalele efectuate de personalul abilitat ;
          </p>
          <p>
            AST este valabilă pentru sectoarele de drum de interes național si
            autostrazi aflate in administrarea C.N.A.I.R. S.A.
          </p>
          <p>
            AST nu este valabila pe sectoarele de drumuri naționale situate în
            intravilanul municipiilor/reședințelor de județ, pe drumurile
            judetene, pe drumurile comunale sau pe alte categorii de drumuri.
            Pentru circulația pe aceste categorii de drumuri, operatorul de
            transport va solicita AST de la administratorul drumului respectiv.
          </p>
          <p>
            AST este valabilă pentru numărul de circulație al vehiculului cu
            depășiri, inclusiv cel al vehiculului de rezervă înscrise în
            aceasta, traseul, sensul, perioada și caracteristicile autorizate
            înscrise, în condițiile stabilite de emitentul acesteia.
          </p>
          <p>
            Circulația vehiculelor cu depasiri este interzisa în zilele de
            sâmbătă, duminică, în zilele premergătoare zilelor de sărbătoare
            legală şi în zilele de sărbătoare legală, între orele 6.00 și 22.00.
          </p>
          <p>
            Circulația vehiculelor cu depasiri este interzisa in conditii de
            vizibilitate redusa sub 100 m, din cauza cetii, ploii sau ninsorii
            abundente ori cand partea carosabila este acoperita cu polei, gheata
            sau zapada, precum si in cazul unor fenomene naturale ce afecteaza
            siguranta circulatiei, semnalate prin instituirea de catre
            institutiile abilitate uneia din avertizarile de cod galben,
            portocalui sau rosu.
          </p>
          <p>
            Operatorul de transport (Titularul AST) are obligația de a se
            informa cu privire la eventualele restricții de circulație
            instituite/ existente pe traseul ce urmează a fi parcurs de
            vehiculul cu depășiri.
          </p>
          <p>
            Circulația vehiculului cu depășiri se va face cu respectarea
            restricțiilor de circulație existente pe traseul ce urmează a fi
            parcurs de vehiculul cu depășiri.
          </p>
          <p>
            Responsabilitatea fixării și ancorării încărcăturii pe vehicul
            revine operatorului de transport, acesta fiind răspunzător de
            prejudiciile şi accidentele produse din cauza deplasării
            necontrolate a încărcăturii în timpul deplasarii vehiculului.
          </p>
          <p>
            Pentru subtraversarea conductelor, rețelelor aeriene şi a altor
            instalații, operatorul de transport are obligația de a obține, în
            prealabil parcurgerii traseului, avizele unităților care le
            administrează, în vederea ridicării, demontării și remontării
            acestora, după caz.
          </p>
          <p>
            Traversarea de către vehiculele cu depășiri a pasajelor la nivel cu
            calea ferată, unde există semnalizare automată, se va efectua în
            maximum 30 de secunde; pentru cazurile care impun depășirea acestui
            timp de trecere operatorul de transport va obține, în prealabil,
            avizul administratorului infrastructurii feroviare.
          </p>
          <p>
            Pentru traversarea pasajelor la nivel cu calea ferată operatorul de
            transport va obține, în prealabil, aprobarea administratorului
            infrastructurii feroviare, în cazul vehiculelor cu lățimi mai mari
            decât lățimea parții carosabile din zona de trecere la nivel cu
            calea ferată, înălțimi mai mari de 4,0 m (în cazul liniilor
            electrificate), lungimi mai mari de 30,0 m;
          </p>
          <p>
            Operatorul de transport va respecta toate condițiile de trecere pe
            pasajele la nivel cu calea ferată stabilite de administratorul
            infrastructurii feroviare;
          </p>
          <p>
            Circulația vehiculelor cu depășiri ale masei totale și/sau ale
            maselor pe axe maxime admise, pe poduri, pe pasaje superioare sau
            viaducte se va efectua cu viteza maximă de 10 km/h, fără opriri,
            șocuri, pe banda din dreapta în sensul de mers, dacă nu sunt impuse,
            prin AST, alte condiții speciale de traversare.
          </p>
          <p>
            Vehiculele a căror masă totală este mai mare de 80,0 tone, vor
            circula pe poduri, pe pasaje superioare sau viaducte, cu
            întreruperea obligatorie, temporară și locală a circulației de către
            politia rutieră, pentru traversarea singulară a lucrării de artă, pe
            axul acesteia.
          </p>
          <p>
            Vehiculul care circulă cu depășirea dimensiunilor și/sau maselor
            maxime admise trebuie să fie echipat în conformitate cu prevederile
            Normelor privind autorizarea şi desfășurarea circulației vehiculelor
            rutiere cu mase și/sau dimensiuni ce depășesc masele şi/sau
            dimensiunile maxime admise prevăzute în Ordonanța Guvernului nr.
            43/1997 privind regimul drumurilor.
          </p>
          <p>
            Însoțirea vehiculelor care circulă cu depășiri se realizează numai
            de operatori economici specializați, autovehicule de însoțire și
            personal specializat.
          </p>
          <p>
            Autovehiculul de însoțire se dotează, obligatoriu, în conformitate
            cu prevederile Normelor privind autorizarea și desfășurarea
            circulației vehiculelor rutiere cu mase şi/sau dimensiuni ce
            depășesc masele și/sau dimensiunile maxime admise prevăzute în
            Ordonanța Guvernului nr. 43/1997 privind regimul drumurilor.
          </p>
          <p>
            Personalul specializat, atestat în vederea însoțirii vehiculelor cu
            depășiri are obligațiile stabilite prin Normele privind autorizarea
            și desfășurarea circulației vehiculelor rutiere cu mase și/sau
            dimensiuni ce depășesc masele și/sau dimensiunile maxime admise
            prevăzute în Ordonanța Guvernului nr. 43/1997 privind regimul
            drumurilor.
          </p>
        </div>
      )}
      {locale === 'en' && (
        <div style={{ height: '400px', overflow: 'auto', margin: '4rem 0' }}>
          <p>
            THE UNDERSIGNED {form.getFieldValue('applicantName')} HEREBY
            ACKNOWLEDGES WITH:
          </p>
          <p>
            After displaying the payment amount, the client may accept the
            payment or cancel the request.
          </p>

          <p>
            Upon acceptance of the payment, by checking the “Pay” box, the
            client irrevocably agrees to transfer the amount representing the
            additional usage fee to the account of C.N.A.I.R. S.A. The
            acceptance cannot be revoked.
          </p>

          <p>
            The client must make the payment no later than 24:00 on the day
            prior to the first validity day of the AST.
          </p>

          <p>
            In case of non-payment, the request will be converted into an
            estimate, and the client will be charged the equivalent in lei of 50
            Euros (VAT included).
          </p>

          <p>
            If there are unpaid amounts, the client will not be able to initiate
            another request until the outstanding amounts owed to C.N.A.I.R.
            S.A. are settled.
          </p>

          <p>
            C.N.A.I.R. S.A. reserves the right to take all legal measures to
            recover unpaid amounts.
          </p>

          <p>
            Traffic on Saturdays, Sundays, days preceding legal holidays, and on
            legal holidays, between 06:00 and 22:00, is only allowed if this
            possibility is stated in the AST.
          </p>

          <p>
            Vehicles with excess dimensions may circulate on public roads only
            if:
          </p>

          <p>a) They are allowed for circulation;</p>

          <p>
            b) They comply with technical condition and road safety
            requirements;
          </p>
          <p>
            c) They do not exceed the maximum permitted weights structurally
            allowed by the vehicles;
          </p>
          <p>
            d) They hold an AST issued by the road administrator on which they
            circulate and comply with the provisions stipulated in the AST;
          </p>
          <p>
            The AST holder is the transport operator, who is fully responsible
            for the circulation of the vehicle with excess dimensions and is
            obligated to comply with the conditions imposed by the AST;
          </p>
          <p>
            A copy of the AST must be on board the vehicle with excess
            dimensions throughout the entire period of circulation, and it is
            mandatory to present it during inspections carried out by authorized
            personnel;
          </p>
          <p>
            The AST is valid for the national road sectors and highways
            administered by C.N.A.I.R. S.A.
          </p>
          <p>
            The AST is not valid on national road sectors located within the
            urban areas of municipalities/counties, on county roads, communal
            roads, or other categories of roads. For circulation on these types
            of roads, the transport operator must request an AST from the
            respective road administrator.
          </p>
          <p>
            The AST is valid for the license plate number of the vehicle with
            excess dimensions, including the spare vehicle listed, the route,
            direction, period, and authorized characteristics as listed, under
            the conditions established by its issuer.
          </p>
          <p>
            The circulation of vehicles with excess dimensions is prohibited on
            Saturdays, Sundays, days preceding legal holidays, and on legal
            holidays, between 06:00 and 22:00.
          </p>
          <p>
            The circulation of vehicles with excess dimensions is prohibited
            under reduced visibility conditions of less than 100 meters due to
            fog, heavy rain, or snow, or when the road surface is covered with
            ice, frost, or snow, as well as in the case of natural phenomena
            that affect traffic safety, signaled by the issuance of yellow,
            orange, or red warnings by the competent institutions.
          </p>
          <p>
            The transport operator (AST holder) is responsible for informing
            themselves about any traffic restrictions instituted/existing on the
            route to be taken by the vehicle with excess dimensions.
          </p>
          <p>
            The circulation of the vehicle with excess dimensions must be
            carried out in compliance with the existing traffic restrictions on
            the route to be taken by the vehicle with excess dimensions.
          </p>
          <p>
            The responsibility for securing and anchoring the load on the
            vehicle lies with the transport operator, who is liable for any
            damages and accidents caused by the uncontrolled movement of the
            load during the vehicle`s journey.
          </p>
          <p>
            For the underpassing of pipelines, air networks, and other
            installations, the transport operator is required to obtain, prior
            to traveling the route, the necessary approvals from the units
            managing them, for the lifting, dismantling, and reassembly of these
            installations, as applicable.
          </p>
          <p>
            The crossing of level crossings with railroads, where automatic
            signaling exists, by vehicles with excess dimensions will be carried
            out within a maximum of 30 seconds; in cases where this crossing
            time is exceeded, the transport operator must obtain, in advance,
            the approval of the railway infrastructure administrator.
          </p>
          <p>
            For crossing level crossings with railways, the transport operator
            must obtain prior approval from the railway infrastructure
            administrator in cases where vehicles exceed the width of the
            roadway at the level crossing, have heights greater than 4.0 meters
            (for electrified lines), or have lengths greater than 30.0 meters
          </p>
          <p>
            The transport operator must comply with all crossing conditions at
            level crossings established by the railway infrastructure
            administrator;
          </p>
          <p>
            The circulation of vehicles with excess total mass and/or maximum
            permitted axle loads on bridges, overpasses, or viaducts shall be
            carried out at a maximum speed of 10 km/h, without stopping, without
            shocks, on the right lane in the direction of travel, unless other
            special crossing conditions are imposed by the AST.
          </p>
          <p>
            Vehicles with a total mass greater than 80.0 tons will circulate on
            bridges, overpasses, or viaducts with the mandatory, temporary, and
            local interruption of traffic by the traffic police for the single
            crossing of the work of art, on its axis.
          </p>
          <p>
            The vehicle exceeding the maximum permitted dimensions and/or masses
            must be equipped in accordance with the provisions of the Norms
            regarding the authorization and conduct of road traffic of vehicles
            with masses and/or dimensions that exceed the maximum permitted
            masses and/or dimensions as provided by Government Ordinance No.
            43/1997 regarding the road regime.
          </p>
          <p>
            The escorting of vehicles circulating with excess dimensions is
            carried out only by specialized economic operators, escort vehicles,
            and specialized personnel.
          </p>
          <p>
            The escort vehicle must be equipped, mandatorily, in accordance with
            the provisions of the Norms regarding the authorization and conduct
            of road traffic of vehicles with masses and/or dimensions that
            exceed the maximum permitted masses and/or dimensions as provided by
            Government Ordinance No. 43/1997 regarding the road regime.
          </p>
          <p>
            The specialized personnel, certified for the escorting of vehicles
            with excess dimensions, have the obligations established by the
            Norms regarding the authorization and conduct of road traffic of
            vehicles with masses and/or dimensions that exceed the maximum
            permitted masses and/or dimensions as provided by Government
            Ordinance No. 43/1997 regarding the road regime.
          </p>
        </div>
      )}
      <Form.Item name="accept">
        <Radio.Group
          options={options}
          optionType="button"
          buttonStyle="solid"
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export default TermsTab;
