import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import PermitsList from '../../components/east/PermitsList';
import { findAll } from '../../services/message';

import { Default } from '../../layouts';

const Permits = () => {
  const history = useHistory();

  useEffect(() => {
    if (!history.location.key) {
      findAll({
        pageSize: 5000,
        pageNumber: 1,
      }).then((res) => {
        const loginMessages = res.content.filter(
          (item) =>
            item.name === 'MESSAGE_AFTER_LOGIN' &&
            new Date(item.validAt) <= new Date() &&
            new Date(item.validTo) > new Date() &&
            item.deleted !== true,
        );

        loginMessages.forEach((message) => {
          notification.info({
            message: message.expression,
            duration: 0,
          });
        });
      });
    }
  }, [history]);

  return (
    <Default>
      <PermitsList />
    </Default>
  );
};

export default Permits;
