import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Divider, Input, Row, Col } from 'antd';
import RouteOriginSelect from '../../../RouteOriginSelect';
import TransrouteDisplay from './TransrouteDisplay';

const RoutesSection = () => {
  const { t } = useTranslation();

  return (
    <Form.List name="routeOptions">
      {(fields) => (
        <>
          {fields.map(({ fieldKey, name }, idx) => (
            <div key={fieldKey} className="route">
              <Divider plain>
                {t('entity.east.permit.route')} {idx + 1}
              </Divider>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={[name, 'dateStart']}
                    fieldKey={[fieldKey, 'dateStart']}
                    label={`${t('entity.east.permit.dateStart')} ${idx + 1}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'originId']}
                    label={t('entity.east.permit.originId')}
                  >
                    <RouteOriginSelect readOnly open={false} />
                  </Form.Item>

                  <Form.Item
                    name={[name, 'totalWeight']}
                    label={t('entity.east.permit.totalWeight')}
                  >
                    <Input readOnly />
                  </Form.Item>

                  <Form.Item
                    name={[name, 'oversizedLength']}
                    label={t('entity.east.permit.length')}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={[name, 'dateEnd']}
                    label={`${t('entity.east.permit.dateEnd')} ${idx + 1}`}
                  >
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'destinationId']}
                    label={t('entity.east.permit.destinationId')}
                  >
                    <RouteOriginSelect readOnly open={false} />
                  </Form.Item>

                  <Form.Item
                    name={[name, 'oversizedHeight']}
                    label={t('entity.east.permit.heigth')}
                  >
                    <Input readOnly />
                  </Form.Item>

                  <Form.Item
                    name={[name, 'oversizedWidth']}
                    label={t('entity.east.permit.width')}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <>
                <Form.List name={[name, 'axisWeight']}>
                  {(fields1) => (
                    <>
                      <Divider plain>Masa pe axe</Divider>
                      <Row gutter={16} span={6}>
                        {fields1.map(
                          ({ name: name1, fieldKey: fieldKey1 }, index) => (
                            <Col
                              xs={12}
                              sm={12}
                              md={8}
                              lg={6}
                              key={fieldKey1}
                              className="route"
                            >
                              <Form.Item
                                name={[name1]}
                                fieldKey={[fieldKey1]}
                                label={`${t(
                                  'entity.east.permit.weightOnAxis',
                                )} ${index + 1}`}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                          ),
                        )}
                      </Row>
                    </>
                  )}
                </Form.List>
              </>

              <Form.Item
                name={[name, 'transroute']}
                fieldKey={[fieldKey, 'transroute']}
                label={t('entity.east.permit.routeDescription')}
              >
                <TransrouteDisplay />
              </Form.Item>
            </div>
          ))}
        </>
      )}
    </Form.List>
  );
};
export default RoutesSection;
